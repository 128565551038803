import React from "react"
import PropTypes from "prop-types"
import "lazysizes"

import { GatsbyImage } from "gatsby-plugin-image"
import { Link, graphql } from "gatsby"

import makeStyles from "@material-ui/core/styles/makeStyles"

import Container from "@material-ui/core/Container"
import Grid from "@material-ui/core/Grid"
import ButtonBase from "@material-ui/core/ButtonBase"
import Typography from "@material-ui/core/Typography"
import Paper from "@material-ui/core/Paper"

import SEO from "../components/SEO"
import { localizedSlugPrefix } from "../gatsby/nodeHelpers"
import Breadcrumbs from "../components/Breadcrumbs"
import SectionTitle from "../components/SectionTitle"


import "../styles/proceduresPage.scss"

const useStyles = makeStyles(theme => ({
  image: {
    display: "block",
    maxHeight: 200,
    width: "100%",
  },
  buttonWrapper: {
    marginBottom: 5,
  },

  icon: {
    marginRight: 15,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center left",
    color: "transparent",
    height: 60,
    width: 60,
  },

  headingText: {
    color: "#fff",
    fontWeight: 600,
    textAlign: "left",
    fontSize: '1.2rem'
  },
}))

const GuidesPage = ({
  data: { guidesPage },
  pageContext: { locale, slug, breadcrumbs },
  location,
}) => {
  const classes = useStyles()

  const schema = guidesPage.data.schema_json.text || ""
  const ogp = guidesPage.data.ogp_tags || ""
  const meta = {
    title: guidesPage.data.meta_title,
    description: guidesPage.data.meta_description,
    keywords: guidesPage.data.meta_keywords,
    author: "Medicina Interventionala",
  }

  return (
    <>
      <SEO
        schema={schema}
        ogp={ogp}
        meta={meta}
        locale={locale}
        breadcrumbs={breadcrumbs}
      />
      <main>
        <Container maxWidth="lg">
          <Breadcrumbs breadcrumbs={breadcrumbs} />
          <br />
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <SectionTitle component="h1">
                {guidesPage.data.title}{" "}
                <span style={{ fontWeight: 700 }}>MONZA ARES</span>
              </SectionTitle>
            </Grid>
            <Grid item xs={12}>
              {guidesPage.data.image.gatsbyImageData && (
                <GatsbyImage
                  alt={guidesPage.data.meta_title || 'Ghidul pacientului'}
                  image={guidesPage.data.image.gatsbyImageData}
                  className={classes.image}
                />
              )}
            </Grid>

            <Grid item xs={12}>
              {guidesPage.data.guides.filter(({ guide }) => guide.document).map(({ guide }) => (
                <Paper
                  className={classes.buttonWrapper}
                  key={guide.document.id}
                  elevation={1}
                  square
                >
                  <ButtonBase
                    focusRipple
                    className="departmentsButton"
                    component={Link}
                    to={`${localizedSlugPrefix(guidesPage)}/${slug}/${guide.document.uid}/`}
                  >
                    <div
                      className={classes.icon}
                      style={{
                        backgroundImage: `url("${guide.document.data.icon.gatsbyImageData?.images.fallback.src}")`,
                      }}
                    >
                      Icon
                    </div>
                    <Typography className={classes.headingText}>
                      {guide.document.data.title.text}
                    </Typography>
                  </ButtonBase>
                </Paper>
              ))}
            </Grid>
          </Grid>
        </Container>
      </main>
    </>
  )
}

export default GuidesPage

export const query = graphql`
  query GuidesPageQuery($slug: String!, $locale: String!) {    
    guidesPage: prismicGuidesPage(
      uid: { eq: $slug }
      lang: { eq: $locale }
    ) {
      lang
      uid
      data {
        title
        guides {
          guide {
            document {
              ... on PrismicGuide {
                id
                uid                
                data {
                  icon {
                    gatsbyImageData(layout: FIXED, width: 60, height: 65)
                  }
                  title {
                    text
                  }
                }
              }
            }
          }
        }
        image {
          gatsbyImageData(layout: FULL_WIDTH)
        }   
        schema_json {
          text
        }
        ogp_tags {
          content
          property
        }
        meta_title
        meta_description
        meta_keywords
      }
    }
  }
`

GuidesPage.propTypes = {
  data: PropTypes.shape({
    guidesPage: PropTypes.object.isRequired,
  }).isRequired,
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    breadcrumbs: PropTypes.array.isRequired,
  }).isRequired,
  location: PropTypes.object.isRequired,
}
